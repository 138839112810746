/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "theme-ui"
import theme from "../theme/theme"
import { Heading } from "theme-ui"
import Seo from "../components/seo"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <Seo title={title} />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: "40em",
          padding: `0 1.0875rem 1.45rem`,
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `flex-start`,
          height: `100%`,
          width: `100%`,
          flex: `1 1 100%`,
        }}
      >
        <main
          style={{
            flex: "1 1 100%",
          }}
        >
          {children}
        </main>
        <footer
          style={{
            flex: "0 0 auto",
          }}
        >
<a rel="me" href="https://mastodon.social/@colinmegill"/>
          <Heading as="h6" sx={{ my: 4 }}>
            © {new Date().getFullYear()} Colin Megill
          </Heading>
        </footer>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
